import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid, Stack, ToggleButton, ToggleButtonGroup
} from "@mui/material";
import "moment/locale/es";
import SectionHeader from "../../../components/molecules/SectionHeader";
import { format } from "date-fns";

import IconoResumenDiario from '../../../assets/icons/icono-resumen-diario.png';
import IconoProdTotal from '../../../assets/icons/icono-prod-total.png';
import IconoEstadoEquipos from '../../../assets/icons/icono-estado-equipos.png';
import IconoProdPortales from '../../../assets/icons/icono-prod-portal.png';
import IconoControlTiempo from '../../../assets/icons/icono-control-tiempo.png';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import HorizontalTimeChart from "../HorizontalTimeChart";

import '../../../translator';
import { useTranslation } from 'react-i18next';
import ResumenDiarioCard1 from "./ResumenCard1";
import EstadoJumboCard from "./EstadoJumboCard";
import IconoProdEquipoLHD from '../../../assets/icons/icono-prod-equipo-lhd.png'
import CategoricalMultiBarChart from "../CategoricalMultiBarChart";

import { useHistory } from "react-router";
import BarTimelineChartVacio from "../../Produccion/BarTimelineChartVacio";
import { useGetTirosVoladurasJumbos } from "../../../hooks/useGetTirosVoladurasJumbos";
import { convertHoursToHoursAndMinutes, toHoursAndMinutes } from "../../../commons/FormatearTiempo";
import CategoricalBarChartJumbos from "../CategoricalBarChartJumbos";
import { PopupRegistroJustificacion } from "../../Justificacion/PopupRegistroJustificacion";

import { useGetGruposMotivosJustificaciones } from "../../../hooks/useGetGruposMotivosJustificaciones";
import { getUltimaUbicacionJustificacion } from "../../../services/justificacion";
import { AlertaGenerica } from "../../../components/molecules/AlertaGenerica";
import { TablaEstadosEquipos } from "../TablaEstadosEquipos";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableChartIcon from '@mui/icons-material/TableChart';
import { TablaDeCarga } from "../../../components/organisms/TablaDeCarga";
import { CircularProgress } from "@material-ui/core";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: "5px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#ff6600",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#00b7c4",
    borderRight: "2px solid #fff",
    borderRadius: "5px"
  },
}));

const LinearProgressOk = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: "5px",
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#85cd4b",
  },
}));

const useStyles = makeStyles({
  prodDes:{
    color: "#2f4554",
    fontSize: "12px !important",
  },
  prodValue:{
    fontSize: "20px",
    fontWeight:"bold"
  },
  root: {
    backgroundColor: "#2f4554",
    margin: "8px 0",
    color: "white",
    "&:hover": {
      backgroundColor: "#253642",
    },
    "&:disabled": {
      backgroundColor: "gray",
    },
  }
});

const JumboView = ({
    idAreaOp, 
    fecha, 
    tipoTurno, 
    onChangeTurno, 
    minMax, 
    loadingMovimientos,
    tipoFlota,
    setReloadCharts,
    metas,
    listadoFlotaSeleccionada,
    movimientoPercutor,
    loadingMovimientosMaquinasParalelos,
    setLoadingMovimientosMaquinasParalelos
}) => {
  const classes = useStyles()
  const { t} = useTranslation();
  const [loadingPopUp, setLoadingPopUp] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [open, setOpen] = useState(false);
  const {TirosYvoladuras, jumbosMotorYPercutor, produccionTotal,LoadingTirosYVoladuras} = useGetTirosVoladurasJumbos(idAreaOp,fecha,tipoFlota);
  const {gruposJustificaciones,loadingGrupoJustificaciones} = useGetGruposMotivosJustificaciones(idAreaOp);
  const [verEstadosEquiposEnTarjetas, setVerEstadosEquiposEnTarjetas] = useState(false);
  const [alignment, setAlignment] = React.useState('modoTabla');
  let history = useHistory();

  const onClickResumenCard = (t) => {
    onChangeTurno(t);
  }
  const onClickMaquinaJumbo = (maq) =>{
    localStorage.setItem("dataOpMaq",JSON.stringify(maq));

    localStorage.setItem("estadosJumbo",JSON.stringify({tipoTurno,minMax}));
    localStorage.setItem("IdMina",idAreaOp);
    history.push(`/operaciones/maquina/jumbo`);
   } 


  const getPorcentajeProduccion = () => {
    //lo transformo a minutos
    const totalProduccion = (produccionTotal * 60);
    
    let proyectado = (metas?.[tipoFlota]?.cantidad) ? Number(metas[tipoFlota].cantidad) : 600;

    if(totalProduccion == 0){
      return  0
    } else if( proyectado == 0){
      return 0;
    }
    return ((totalProduccion / proyectado) * 100);
  }


  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ open: false, message: alert.message, severity: alert.severity });
  };

  const [stateInicialFormJustificaciones, setstateInicialFormJustificaciones] = useState({
    fecha_inicio:'',
    fecha_termino:'',
    id_movimiento:0,
    id_registro_movimiento:0,
    id_maquina:0,
    nombre_motivo:'',
    ubicacion:''
   })
  
  const handleClickOpen = (id_movimiento, fecha_inicio, fecha_termino, id_t_registro_movimiento, id_maquina) => {
    setOpen(true);
    setLoadingPopUp(true);
    
    setstateInicialFormJustificaciones(prevState =>{
      return {
        ...prevState,
        fecha_inicio,
        fecha_termino,
        id_movimiento,
        id_t_registro_movimiento,
        id_maquina
      }
    })
    getUltimaUbicacionJustificacion(idAreaOp, id_t_registro_movimiento)
    .then((resp) => {
      setstateInicialFormJustificaciones(prevState =>{
        return {
          ...prevState,
          ubicacion: resp.data.nombre
        }
      })
      setLoadingPopUp(false);
    })
    .catch((err) => {
      console.log(err);
      setLoadingPopUp(false);
    });

  };

  const handleTipoVistaEstadosMaquinas = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      if(newAlignment == "modoTarjeta"){
        setVerEstadosEquiposEnTarjetas(true)
      }else{
        setVerEstadosEquiposEnTarjetas(false)
      }
    }
  };

  return (
    <>
      <PopupRegistroJustificacion
          FormJustificados={false}
          classes={classes}
          loadingPopUp={loadingPopUp}
          open={open}
          gruposJustificaciones={gruposJustificaciones}
          verCheckbox = {true}
          stateInicialFormJustificaciones={stateInicialFormJustificaciones}
          setReloadCharts={setReloadCharts}
          setLoadHorizontalChart={setLoadingMovimientosMaquinasParalelos}
          setOpen={setOpen}
          setAlert={setAlert}
        />

    <AlertaGenerica alert={alert} handleCloseAlert={handleCloseAlert} duration={5000} />
    {
      LoadingTirosYVoladuras ? 
      <Grid container justifyContent={'center'}>    
          <CircularProgress />
      </Grid> : 
    <Grid>
      <SectionHeader 
        title={t("resumen_diario_brazos")}
        subtitle={`${t("ultima_actualizacion")} ${format(fecha, "HH:mm")} hrs`}
        icon={<img src={IconoResumenDiario} width="35px" alt=" Icono Resumen Diario"/>}
      />

      <br/>


      <Grid container spacing={1}>
      {
          Object.entries(TirosYvoladuras).map(([key, value])=>{
            return(
              <Grid key={key} item lg={4} md={12} xs={12}>
                <ResumenDiarioCard1
                  general={true}
                  id={key}
                  onClick={onClickResumenCard}
                  titulo1={key==="turnotodos"?t("detalle_diario_brazos"):t("detalle_de")}
                  titulo2={key==="turnotodos"?"":t(`turno_${key.toLowerCase()}`)}
                  horas={value.voladuras}
                  horasMotor={value.tiros}
                  isSelect={tipoTurno===key}
                  tipoFlota={tipoFlota}
                  loading={false}
                />
              </Grid>
            )
          })
        }
      </Grid>

      <br/>
      <br/>
      <br/>

      <SectionHeader 
            title={t("prod_total")}
            subtitle={`${t("ultima_actualizacion")} ${format(fecha, "HH:mm")} hrs`}
            icon={<img src={IconoProdTotal} width="35px" alt=" Icono Produccion Total"/>}
          />

      {/* PRODUCCION TOTAL */}
      <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
             <Grid item lg={3} md={3} xs={3} sx={{borderRight:"2px solid #d7d7d7"}}>
              <Grid
                container
                direction="column"
                alignItems="center"
              >
                <div className={classes.prodValue}  style={{color:(getPorcentajeProduccion()<100)?"#00b7c4":"#85cd4b"}}>
                  {convertHoursToHoursAndMinutes(produccionTotal)}
                </div>
                <div className={classes.prodDes}>
                  {t("horas_percutidas")}
                </div>
              </Grid>
            </Grid>
            
            <Grid item lg={3} md={3} xs={3} sx={{borderRight:"2px solid #d7d7d7"}}>
              <Grid
                container
                direction="column"
                alignItems="center"
              >
                <div className={classes.prodValue} style={{color:"#2f4554"}}>
                 {toHoursAndMinutes((metas?.[tipoFlota]?.cantidad) ? Number(metas[tipoFlota].cantidad) : 600)}
                </div>
                <div className={classes.prodDes}>
                  {t("horas_programadas")}
                </div>
              </Grid>
            </Grid>
          
          </Grid>

          <br/>
          {
            (getPorcentajeProduccion(produccionTotal*2))<100?
              <BorderLinearProgress 
                variant="determinate" 
                value={getPorcentajeProduccion(produccionTotal*2)} 
              />
            :
              <LinearProgressOk
                variant="determinate" 
                value={100} 
              />
          }

    </Grid>

    }

      <br/>
      <br/>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item xs={11}>
          <SectionHeader 
            title={t("estado_equipos")}
            subtitle={`${t("ultima_actualizacion")} ${format(fecha, "HH:mm")} hrs`}
            icon={<img src={IconoEstadoEquipos} width="35px" alt=" Icono Estado Equipos"/>}
          />
        </Grid>

        <Grid item xs={1}>
          <Stack direction={'row'}>
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleTipoVistaEstadosMaquinas}
              aria-label="text alignment"
            >
              <ToggleButton value="modoTarjeta" aria-label="left aligned">
                <ViewModuleIcon style={{color:'#2f4554'}}/>
              </ToggleButton>
              <ToggleButton value="modoTabla" aria-label="centered">
                <TableChartIcon style={{color:'#2f4554'}}/>
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
      </Grid>
      <br/>
      <br/>

      {
        !verEstadosEquiposEnTarjetas ? (
        loadingMovimientos ?
            <TablaDeCarga
            columnas={[
              t("maquina"), 
              t("estado"), 
              t("vueltas"),
              t("toneladas"),
              t("ultima_actualizacion")
            ]}
          />:
          (listadoFlotaSeleccionada && listadoFlotaSeleccionada.length >0) &&
          <TablaEstadosEquipos
            dataVueltas={[]}
            fechaActual={''}
            tiemposArOpMaq={[]}
            tipoTurno={tipoTurno}
            data={listadoFlotaSeleccionada}
            isJumbo={true}
            jumbosMotorYPercutor={jumbosMotorYPercutor}
            accion={onClickMaquinaJumbo}
            flotaSeleccionada={listadoFlotaSeleccionada}
            tipoFlota={tipoFlota}
            idMina={idAreaOp}
          />)
         : (
          <Grid container spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {
              listadoFlotaSeleccionada.map((maq,index)=>(
                <Grid item lg={5} md={6} xs={12} key={index}>
                  <EstadoJumboCard
                    nombreMaquina={maq.nombre}
                    estado={maq.id_tipo_movimiento}
                    horometro_motor_brazos={jumbosMotorYPercutor[maq.nombre]?.horometroMotor}
                    horometro_percutor_brazos={jumbosMotorYPercutor[maq.nombre]?.horometroPercutor }
                    onClick={onClickMaquinaJumbo}
                    ultimaUbicacion={maq.tipo_ultima_ubicacion}
                    tiempoUltimaUbicacion={maq?.hora_ultima_ubicacion}
                    allMaqData={maq}
                  />
              </Grid>
              ))
            }
          </Grid>

        )
      }
      

{
//TEMPORAR OCULTAR HOROMETRO MINA BURITICA
idAreaOp !== 16 && <div>
  
    <br/>
    <br/>

    <SectionHeader
      title={t("horometro")}
      subtitle={`${t("ultima_actualizacion")} ${format(fecha, "HH:mm")} hrs`}
      icon={<img src={IconoControlTiempo} width="35px" alt=" Icono Resumen Diario"/>}
    />

     <br/>
    <br/>
    
    <Grid 
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
        <Grid container justifyContent="center" alignItems="center" >
          <span 
            style={{
              margin:"0px 5px 0px 0px",
              height:"13px",
              width:"13px",
              backgroundColor: "#01b7c4",
              borderRadius:"50%",
              display: "inline-block"
            }}
          />
          <span className={classes.prodDes} style={{marginLeft:"3px"}}>
            {t("operativo")}
          </span>
        </Grid>
      </Grid>
      <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
        <Grid container justifyContent="center" alignItems="center" >
          <span 
            style={{
              margin:"0px 5px 0px 0px",
              height:"13px",
              width:"13px",
              backgroundColor: "#E9724C",
              borderRadius:"50%",
              display: "inline-block"
            }}
          />
          <span className={classes.prodDes} style={{marginLeft:"3px"}}>
            {t("ralenti")}
          </span>
        </Grid>
      </Grid>
      <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
      <Grid container justifyContent="center" alignItems="center" >

        <span 
          style={{
            margin:"0px 5px 0px 0px",
            height:"13px",
            width:"13px",
            backgroundColor: "#ffbf00",
            borderRadius:"50%",
            display: "inline-block"
          }}
        />
        <span className={classes.prodDes} style={{marginLeft:"3px"}}>
          {t("en_mantencion")}
        </span>
        </Grid>
      </Grid>
      <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
      <Grid container justifyContent="center" alignItems="center" >

        <span 
          style={{
            margin:"0px 5px 0px 0px",
            height:"13px",
            width:"13px",
            backgroundColor: "#C5283D",
            borderRadius:"50%",
            display: "inline-block"
          }}
        />
        <span className={classes.prodDes} style={{marginLeft:"3px"}}>
          {t("fuera_de_servicio")}
        </span>
        </Grid>
      </Grid>

      <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
        <Grid container justifyContent="center" alignItems="center" >
          <span 
            style={{
              margin:"0px 5px 0px 0px",
              height:"13px",
              width:"13px",
              backgroundColor: "#28B463",
              borderRadius:"50%",
              display: "inline-block"
            }}
          />
          <span className={classes.prodDes} style={{marginLeft:"3px"}}>
            {t("Justificado")}
          </span>
        </Grid>
      </Grid>
      
      <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important"}}>
      <Grid container justifyContent="center" alignItems="center" >

        <span 
          style={{
            margin:"0px 5px 0px 0px",
            height:"13px",
            width:"13px",
            backgroundColor: "#d6dce4" ,
            borderRadius:"50%",
            display: "inline-block"
          }}
        />
        <span className={classes.prodDes} style={{marginLeft:"3px"}}>
          {t("sin_info")}
        </span>
        </Grid>
      </Grid>
    </Grid>
    <br/>
    {
       loadingMovimientosMaquinasParalelos ? 
       <div>
        <br/>
        <br/>
        <Grid container justifyContent="center" alignItems="center"> 
            
           <CircularProgress />
        </Grid> 
       </div>:(
      movimientoPercutor?.length > 0 ? 
      (
        movimientoPercutor?.map((estadoData,index) =>(
            <HorizontalTimeChart
            heightGrafico={105}
            key={index}
            series={estadoData}
            turno={minMax}
            numCategorias={1}
            abrirModalJustificaciones={handleClickOpen}
            fullHeight={true}
  
          />
          
        ))) :
        (
          <BarTimelineChartVacio
                limites={minMax}
                labels={['']}
                height="130"
                loading={loadingMovimientos}
            />

        )
      )
    }
  </div>
}

       <br/>
          <br/>
          <SectionHeader 
            title={t("prod_por_equipo")}
            icon={<img src={IconoProdEquipoLHD} width="45px" alt=" Icono Produccion por Equipo"/>}
          />    
          <Grid container justifyContent="space-between" alignItems="center" sx={{padding:"0px 30px"}}>
            <Grid item lg={2} md={3} xs={3}>
              <span className={classes.prodDes} style={{fontWeight:"bold"}}>
                {t("horas_percutidas")}
              </span>
            </Grid>
            <Grid item lg={1} md={2} xs={2}>
              {/* <span className={classes.prodDes} style={{fontWeight:"bold"}}>
                {t("horas_percutidas")}
              </span> */}
            </Grid>
          </Grid>
          
          <CategoricalBarChartJumbos 
            dataSeries = {{
              categorias:Object.entries(jumbosMotorYPercutor).map(([key,value]) => key),
              percutido:Object.entries(jumbosMotorYPercutor).map(([key,value]) => value.horometroPercutor ? value.horometroPercutor : 0)
            }}
            multTonelaje = {30}
            numCategorias={1}
          />

          <br/>
          <br/>
          <SectionHeader 
            title={"Producción por Voladuras"}
            icon={<img src={IconoProdPortales} width="35px" alt=" Icono Produccion por Portal"/>}
          />
          <br/>
          <br/>

          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item lg={3} md={3} xs={3} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
              <Grid container justifyContent="center" alignItems="center">
                <span 
                  style={{
                    margin:"0px 5px 0px 0px",
                    height:"13px",
                    width:"13px",
                    backgroundColor: "#00b7c4",
                    borderRadius:"50%",
                    display: "inline-block"
                  }}
                />
                <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                  {"Percutido"/* `Vueltas / Tonelaje Realizadas` */}
                </span>
              </Grid>
            </Grid>
            <Grid item lg={3} md={3} xs={3}sx={{padding:"3px 0px 3px 20px !important"}}>
              <Grid container justifyContent="center" alignItems="center">
                <span 
                  style={{
                    margin:"0px 5px 0px 0px",
                    height:"13px",
                    width:"13px",
                    backgroundColor: "#D6DCE4",
                    borderRadius:"50%",
                    display: "inline-block"
                  }}
                />
                <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                  {t("programado")/* `Vueltas / Tonelaje Programado` */}
                </span>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid container justifyContent="space-between" alignItems="center" sx={{padding:"0px 30px"}}>
            <Grid item lg={1} md={2} xs={2}>
              <span className={classes.prodDes} style={{fontWeight:"bold"}}>
                {t("vueltas")}
              </span>
            </Grid>
            <Grid item lg={1} md={2} xs={2}>
              <span className={classes.prodDes} style={{fontWeight:"bold"}}>
                {"Horas"}
              </span>
            </Grid>
          </Grid>
          <CategoricalMultiBarChart
            colores={["#00b7c4","#D6DCE4"]}
            dataSeries = {{"categorias":["Frente 1","Frente 3","Frente 8","Frente 23","Frente 25","Frente 26"],"vueltas":[0,0,0,0,0,3],"toneladas":[0,0,0,0,0,30]}}
            multTonelaje = {30}   
            numCategorias={6}      
            tonProgramadas={undefined} 
            numPortales={6}
          />

    </>
  )
}

export default JumboView;