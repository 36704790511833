import { useEffect, useState } from "react";
import { getAllBoltersByAreaOperacion, getAllJumbosByAreaOperacion, getInfoJumbo } from "../services/operaciones";
import moment from "moment";

export const useGetTirosVoladurasJumbos = (id_area_operacion, fecha, tipoFlota) => {

    let fechaAux = moment(fecha).format("YYYY-MM-DD"); 

    const [TirosYvoladuras, setTirosYvoladuras] = useState({Dia:{voladuras:0,tiros:0},Noche:{voladuras:0,tiros:0},turnotodos:{voladuras:0,tiros:0}})
    const [LoadingTirosYVoladuras, setLoadingTirosYVoladuras] = useState(false);
    const [jumbosMotorYPercutor, setjumbosMotorYPercutor] = useState([])
    const [produccionTotal, setProduccionTotal] = useState(0);
    const apiFunction = (tipoFlota && tipoFlota==="Jumbo") ? getAllJumbosByAreaOperacion : getAllBoltersByAreaOperacion;
      useEffect(() => {
        setLoadingTirosYVoladuras(true);
        const controller = new AbortController();
        let jumboArrayObj = {};
        let contador = 0;

        apiFunction(id_area_operacion)
          .then((resp) => {
            if (resp.success) {
                const jumboNames = [];

              // Recopilar todas las promesas de getInfoJumbo en un array
              const promises = resp.data.map((jumbo) => {
                jumboNames.push(jumbo.nombre)
                return getInfoJumbo(id_area_operacion, jumbo.id, fechaAux, controller);
              });
      
              // Ejecutar todas las promesas simultáneamente
              Promise.all(promises)
                .then((responses) => {
                  // Inicializar un objeto para mantener el estado
                  const newTirosYvoladuras = {
                    turnotodos:{
                        voladuras:0,
                        tiros:0
                    },
                    Dia: {
                      voladuras: 0,
                      tiros: 0
                    },
                    Noche: {
                      voladuras: 0,
                      tiros: 0
                    }
                  };
                  // Procesar las respuestas y acumular datos
                  responses.forEach((resp2, index) => {
                    let base = {
                        nombre:jumboNames[index],
                        horometroMotor:0,
                        horometroPercutor:0
                    }
                    
                    for (const brazosDia of Object.keys(resp2?.data.Dia)) {
                        if (brazosDia === "motor-diesel") continue;
                      
                        newTirosYvoladuras.Dia.voladuras += resp2.data.Dia[brazosDia].voladuras;
                        newTirosYvoladuras.Dia.tiros += resp2.data.Dia[brazosDia].tiros;
                        newTirosYvoladuras.turnotodos.voladuras += resp2.data.Dia[brazosDia].voladuras;
                        newTirosYvoladuras.turnotodos.tiros += resp2.data.Dia[brazosDia].tiros;
                        base.horometroMotor += resp2.data.Dia[brazosDia].horas.motor;
                        base.horometroPercutor += resp2.data.Dia[brazosDia].horas.percutor
                        contador += resp2.data.Dia[brazosDia].horas.percutor;
                     
                    }
                    
                    for (const brazoNoche of Object.keys(resp2.data.Noche)) {
                      if (brazoNoche === "motor-diesel") continue;

                      newTirosYvoladuras.Noche.voladuras += resp2.data.Noche[brazoNoche].voladuras;
                      newTirosYvoladuras.Noche.tiros += resp2.data.Noche[brazoNoche].tiros;
                      newTirosYvoladuras.turnotodos.voladuras += resp2.data.Noche[brazoNoche].voladuras;
                      newTirosYvoladuras.turnotodos.tiros += resp2.data.Noche[brazoNoche].tiros;
                      base.horometroMotor += resp2.data.Noche[brazoNoche].horas.motor;
                      base.horometroPercutor += resp2.data.Noche[brazoNoche].horas.percutor
                      contador += resp2.data.Noche[brazoNoche].horas.percutor
                      
                    }
                    
                    let name = jumboNames[index];
                    jumboArrayObj={...jumboArrayObj,[name]:base};
                  });
                  setProduccionTotal(contador);
                  setjumbosMotorYPercutor(jumboArrayObj);
                  // Actualizar el estado una vez que todas las promesas se hayan resuelto
                  setTirosYvoladuras(newTirosYvoladuras);
                  setLoadingTirosYVoladuras(false);
                })
                .catch((error) => {
                  // Manejar errores si es necesario
                  setLoadingTirosYVoladuras(false);
                  console.error(error);
                });
            }
          })
          .catch((error) => {
            setLoadingTirosYVoladuras(false);
            // Manejar errores si es necesario
            console.error(error);
          });
      }, [fecha, tipoFlota]);
      

      
    return {TirosYvoladuras, LoadingTirosYVoladuras,jumbosMotorYPercutor, produccionTotal}

}
